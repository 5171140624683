import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTransition } from "react-spring"
import { connect } from "react-redux"
import { FlexContainer, FlexItem } from "../FlexGrid/FlexGrid"

import NavMenu from "./NavMenu"
import MobileMenu from "./MobileMenu"
import MenuSideBar from "../MenuSideBar/MenuSideBar"
import Icons from "./Icons"
import SelectBlock from "./SelectBlock"

import { toggleSelectHeader } from "../../actions/toggleSelectHeader"
import useScrollPosition from "../../APIs/scroll_position"
import HeaderLogo from "./HeaderLogo"
import breakPoints from "../../APIs/devices"

const HeaderContainer = styled.header`
  position: fixed;
  display: ${({ display }) => display};
  background: ${({ color }) => color};
  top: 0;
  left: 0;
  height: var(--header-height-mobile);
  width: 100%;
  z-index: var(--header-zIndex);
  box-shadow: ${({ shadow }) => shadow};
  &:hover {
    background: white;
    box-shadow: 0 -5px 10px rgb(0, 0, 0, 0.2);
  }
  @media only screen and (min-width: ${breakPoints.lg}) {
    height: var(--header-height);
  }
`

const HeaderWrapper = styled(FlexContainer)`
  position: relative;
  height: inherit;
  justify-content: space-evenly;
  align-items: center;
`
const Block = styled(FlexItem)`
  position: relative;
  height: inherit;
  align-items: center;
`
const Mobile = styled(Block)`
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: none;
  }
`

const Desktop = styled(Block)`
  display: none;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: flex;
  }
`

const Header = ({ dispatch, isSideBarOpen, siteLocation }) => {
  // const [color, setColor] = useState("transparent")
  const [color, setColor] = useState("white")
  const [headerBorder, setHeaderBorder] = useState("none")

  const { scrollPosition } = useScrollPosition()

  const transitions = useTransition(isSideBarOpen, null, {
    from: { transform: "translate3d(-100%, 0, 0)" },
    enter: { transform: "translate3d(0, 0, 0)" },
    leave: { transform: "translate3d(-100%, 0, 0)" },
  })

  useEffect(() => {
    let mounted = true

    function determineHeaderColor() {
      if (scrollPosition) {
        if (
          scrollPosition.y === 0 &&
          siteLocation === "/" &&
          document.body.style.position !== "fixed"
        ) {
          setColor("white")
          // setColor("transparent")
          setHeaderBorder("none")
        } else {
          setColor("white")
          setHeaderBorder("0 -5px 10px rgb(0, 0, 0, 0.2)")
        }
      }
    }

    if (mounted) {
      determineHeaderColor()
    }

    return () => {
      mounted = false
      determineHeaderColor()
    }
  }, [scrollPosition, siteLocation])

  return (
    <HeaderContainer id="header-nav" color={color} shadow={headerBorder}>
      {transitions.map(
        ({ item, key, props }) =>
          item && <MenuSideBar key={key} style={props} />
      )}
      <HeaderWrapper>
        <Mobile xs={4}>
          <MobileMenu />
        </Mobile>

        <Block xs={4} onMouseEnter={() => dispatch(toggleSelectHeader(false))}>
          <HeaderLogo />
        </Block>

        <Desktop container lg={4}>
          <NavMenu />
        </Desktop>

        <Block
          id="icons-block"
          xs={4}
          container
          onMouseEnter={() => dispatch(toggleSelectHeader(false))}
        >
          <Icons />
        </Block>
      </HeaderWrapper>
      <SelectBlock />
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isSideBarOpen: PropTypes.bool.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = ({ isSideBarOpen, siteLocation }) => {
  return { isSideBarOpen, siteLocation }
}

export default connect(mapStateToProps)(Header)
