const locationsPage = (
  state = {
    storeCards: {},
    focus: null,
  },
  action
) => {
  switch (action.type) {
    case "ADD_LOCATIONS_STORE_CARD": {
      return {
        ...state,
        storeCards: {
          ...state.storeCards,
          [action.storeID]: {
            expand: false,
          },
        },
      }
    }
    case "EXPAND_LOCATIONS_STORE_CARD": {
      const { storeCards } = state

      //Shrink the other cards
      const newStoreCards = Object.keys(storeCards).reduce((acc, key) => {
        acc[key] = {
          ...storeCards[key],
          expand: key === action.storeID,
        }
        return acc
      }, {})
      return {
        ...state,
        storeCards: newStoreCards,
        focus: action.storeID,
      }
    }

    case "SHRINK_LOCATIONS_STORE_CARD": {
      return {
        ...state,
        storeCards: {
          ...state.storeCards,
          [action.storeID]: {
            expand: false,
          },
        },
        focus: null,
      }
    }
    default:
      return state
  }
}

export default locationsPage
