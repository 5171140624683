import React from "react"
import { useSelector } from "react-redux"
import "./MenuIcon.css"

const MenuIcon = () => {
  const isSideBarOpen = useSelector(state => state.isSideBarOpen)

  return (
    <div id="nav-icon1" className={isSideBarOpen ? "open" : ""}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default MenuIcon
