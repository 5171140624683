const user = (state = null, action) => {
  switch (action.type) {
    case "LOGIN_USER": {
      return action.accessToken
    }
    default:
      return state
  }
}

export default user
