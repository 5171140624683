import rootElement from "./wrap-with-provider"
import pageElement from "./wrap-with-page"

const preferDefault = m => (m && m.default) || m
export const wrapRootElement = preferDefault(rootElement)
export const wrapPageElement = preferDefault(pageElement)

// in gastby-browser.js
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  // return currentPosition === null || currentPosition === 0
  //   ? [0, 0]
  //   : currentPosition

  window.scrollTo(0, 0)
  return false
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
