import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import BagIcon from "../../icons/BagIcon.svg"

import { toggleSideModal } from "../../actions/toggleSideModal"
import breakPoints from "../../APIs/devices"

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  flex-basis: 33%;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  z-index: 100;
  @media only screen and (max-width: ${breakPoints.lg}) {
    padding-right: 10px;
  }
`

const BagWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: block;
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`

const BagWrapperMobile = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  margin-right: 10px;
  margin-left: 0px;
  margin-bottom: 5px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: none;
  }
`

const StyledBagIcon = styled.img`
  position: relative;
  width: inherit;
  height: inherit;
  &:hover {
    cursor: pointer;
  }
`

const BagCountContainer = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  right: 5px;
  top: 8px;
  background-color: transparent;
  color: black;
  padding-bottom: 2px;
  @media only screen and (min-width: ${breakPoints.lg}) {
    top: -7px;
    right: -13px;
    height: 27px;
    width: 27px;
    font-size: 19px;
    pointer-events: none;
    padding-bottom: 4px;
    background-color: black;
    color: white;
  }
`

const Icons = ({ showSideModal, qty, dispatch }) => {
  return (
    <Container>
      <Wrapper>
        <BagWrapperMobile onClick={() => navigate("/checkout/cart")}>
          <StyledBagIcon src={BagIcon} />
          {qty > 0 && <BagCountContainer>{qty}</BagCountContainer>}
        </BagWrapperMobile>

        <BagWrapper
          onClick={() => dispatch(toggleSideModal(!showSideModal.show, "cart"))}
        >
          <StyledBagIcon src={BagIcon} />
          {qty > 0 && <BagCountContainer>{qty}</BagCountContainer>}
        </BagWrapper>
      </Wrapper>
    </Container>
  )
}

const mapStateToProps = ({ showSideModal, cart }) => {
  //Count the number of line items in the cart. Cart is initialized as null. Return 0 for quantity if no items are in the cart.
  const qty =
    cart === null || cart.lineItems === undefined
      ? 0
      : cart.lineItems.reduce((total, item) => {
          return total + item.quantity
        }, 0)
  return { showSideModal, qty }
}

Icons.propTypes = {
  showSideModal: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(Icons)
