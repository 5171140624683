import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import client from "../../../lib/shopifyClient"
import NoImage from "../../../images/no_image.png"
import { navigate } from "gatsby"

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px 1rem 1rem;
`
const ImgContainer = styled.div`
  position: relative;
  flex: 0 0 30%;
  padding: 15px;
  display: flex;
  justify-content: center;
`

const Img = styled.img`
  position: relative;
  height: auto;
  margin: 0 auto;
  width: 125px;
  object-fit: contain;
`
const DescriptionWrapper = styled.div`
  position: relative;
  flex: 0 0 70%;
  padding: 15px;
`

const Title = styled.h3`
  position: relative;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const Price = styled.h3`
  position: relative;
  font-weight: normal;
`

const PriceSpan = styled.span`
  position: relative;
  ${({ discounted, discountAmount }) =>
    discounted &&
    `
      text-decoration: line-through;
      color: darkgrey;
      &:after {
        content: "$${discountAmount}";
        position: absolute;
        bottom: -1rem;
        left: 0;
        color: var(--delete);
      }
  `}
`

const ItemQuantity = styled.span`
  color: darkgrey;
  font-weight: normal;
`

const LineItem = ({
  item: { title, quantity, discountAllocations, variant },
}) => {
  if (variant === null) {
    localStorage.removeItem("checkout_id")
    navigate("/lineitem-change")

    return null
  }


  const {
    price: { amount },
    image,
  } = variant
  const price = Number(amount * quantity).toFixed(2)

  const discountAmount =
    discountAllocations.length > 0
      ? Number(price - discountAllocations[0].allocatedAmount.amount).toFixed(2)
      : ""

  return (
    <Container>
      <ImgContainer>
        <Img
          src={
            image
              ? client.image.helpers.imageForSize(image, {
                maxWidth: 125,
                maxHeight: 125,
              })
              : NoImage
          }
        />
      </ImgContainer>
      <DescriptionWrapper>
        <Title>{title}</Title>
        <Price>
          <PriceSpan
            discounted={discountAllocations.length > 0}
            discountAmount={discountAmount}
          >
            {`$${price}`}
          </PriceSpan>
          <ItemQuantity>{` (${quantity} item${quantity > 1 ? "s" : ""
            })`}</ItemQuantity>
        </Price>
        {/* <FooterWrapper>
          <Quantity>{`QTY:  ${quantity}`}</Quantity>
          <RemoveButton onClick={() => dispatch(removeProductFromCart({id}))}>
            Remove
          </RemoveButton>
        </FooterWrapper> */}
      </DescriptionWrapper>
    </Container>
  )
}

LineItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
}

export default connect()(LineItem)
