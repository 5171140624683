import client from "../lib/shopifyClient"
import apolloClient from "../lib/shopifyApollo"
import gql from "graphql-tag"
import { navigate } from "gatsby"

export default async function handleTaxVariants({ cart, res, rej }) {
  let total = 0
  let items = {
    tax: [],
    noTax: [],
  }

  //Check the number of "Tax" items
  for await (const lineItem of cart.lineItems) {
    const {
      id,
      variant: {
        product: { handle },
        title,
      },
      quantity,
    } = lineItem

    if (title === "Default") {
      total += quantity
      items.tax.push({ lineItemId: id, handle, quantity })
    } else if (title === "No Tax") {
      total += quantity
      items.noTax.push({ lineItemId: id, handle, quantity })
    }
  }

  //If there are more than 6, convert to "No Tax"
  if (total >= 6) {
    const lineItemsToRemove = items.tax.map(item => item.lineItemId)

    //remove the line items
    await client.checkout.removeLineItems(cart.id, lineItemsToRemove)

    const productsToAdd = []
    for await (const item of items.tax) {
      const { handle, quantity } = item

      try {
        const GET_PRODUCT = gql`
          query MyQuery($handle: String!) {
            product(handle: $handle) {
              variants(first: 2, reverse: true) {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
            }
          }
        `

        //Wait for apollo query of the product
        const result = await apolloClient.query({
          query: GET_PRODUCT,
          variables: { handle },
          fetchPolicy: "no-cache",
        })

        console.log(result)

        //Get the variants
        const {
          error,
          data: {
            product: {
              variants: { edges: productVariants },
            },
          },
        } = result

        console.log(error)

        let noTaxVariantId

        //Loop the variants to find the taxless ver.
        for (const productVariant of productVariants) {
          const {
            node: { id, title },
          } = productVariant

          if (title === "No Tax") {
            noTaxVariantId = id
            break
          }
        }

        productsToAdd.push({
          variantId: noTaxVariantId,
          quantity,
          customAttributes: [],
        })
      } catch (e) {
        console.error(e)
        navigate("/error")
      }
    }
    try {
      //add the taxless variant with the same quantity
      await client.checkout.addLineItems(cart.id, productsToAdd)
    } catch (e) {
      console.error(e)
      navigate("/error")
    }
  } else if (total < 6) {
    const lineItemsToRemove = items.noTax.map(item => item.lineItemId)

    //remove the line items
    await client.checkout.removeLineItems(cart.id, lineItemsToRemove)

    const productsToAdd = []
    for await (const item of items.noTax) {
      const { handle, quantity } = item

      try {
        const GET_PRODUCT = gql`
          query MyQuery($handle: String!) {
            product(handle: $handle) {
              variants(first: 2, reverse: true) {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
            }
          }
        `

        //Wait for apollo query of the product
        const result = await apolloClient.query({
          query: GET_PRODUCT,
          variables: { handle },
          fetchPolicy: "no-cache",
        })

        //Get the variants
        const {
          data: {
            product: {
              variants: { edges: productVariants },
            },
          },
        } = result

        let taxVariantId

        //Loop the variants to find the taxless ver.
        for (const productVariant of productVariants) {
          const {
            node: { id, title },
          } = productVariant

          if (title === "Default") {
            taxVariantId = id
            break
          }
        }

        productsToAdd.push({
          variantId: taxVariantId,
          quantity,
          customAttributes: [],
        })
      } catch (e) {
        console.error(e)
        navigate("/error")
      }
    }
    try {
      //add the taxless variant with the same quantity
      await client.checkout.addLineItems(cart.id, productsToAdd)
    } catch (e) {
      console.error(e)
      navigate("/error")
    }
  }

  res()
}

// export default async function handleTaxVariants({ cart, res, rej }) {
//   const itemsToRemove = []
//   const productsToAdd = []

//   //Check cart to see if there are any items for tax exemptions
//   for await (const lineItem of cart.lineItems) {
//     const { id: lineItemId, variant, quantity } = lineItem

//     if (variant.title === "Tax" && quantity >= 6) {
//       try {
//         const {
//           product: { handle },
//         } = variant

//         const GET_PRODUCT = gql`
//           query MyQuery($handle: String!) {
//             product(handle: $handle) {
//               variants(first: 2, reverse: true) {
//                 edges {
//                   node {
//                     id
//                     title
//                   }
//                 }
//               }
//             }
//           }
//         `

//         //Wait for apollo query of the product
//         const result = await apolloClient.query({
//           query: GET_PRODUCT,
//           variables: { handle },
//         })

//         //Get the variants
//         const {
//           data: {
//             product: {
//               variants: { edges: productVariants },
//             },
//           },
//         } = result

//         let noTaxVariantId

//         //Loop the variants to find the taxless ver.
//         for (const productVariant of productVariants) {
//           const {
//             node: { id, title },
//           } = productVariant

//           if (title === "No Tax") {
//             noTaxVariantId = id
//             break
//           }
//         }

//         itemsToRemove.push(lineItemId)
//         productsToAdd.push({
//           id: noTaxVariantId,
//           quantity,
//           customAttributes: [],
//         })
//       } catch (e) {
//         console.error(e)
//         navigate("/error")
//       }
//     } else if (variant.title === "No Tax" && quantity < 6) {
//       try {
//         const {
//           product: { handle },
//         } = variant

//         const GET_PRODUCT = gql`
//           query MyQuery($handle: String!) {
//             product(handle: $handle) {
//               variants(first: 2) {
//                 edges {
//                   node {
//                     id
//                     title
//                   }
//                 }
//               }
//             }
//           }
//         `

//         //Wait for apollo query of the product
//         const result = await apolloClient.query({
//           query: GET_PRODUCT,
//           variables: { handle },
//         })

//         //Get the variants
//         const {
//           data: {
//             product: {
//               variants: { edges: productVariants },
//             },
//           },
//         } = result

//         let taxVariantId

//         //Loop the variants to find the taxless ver.
//         for (const productVariant of productVariants) {
//           const {
//             node: { id, title },
//           } = productVariant

//           if (title === "Tax") {
//             taxVariantId = id
//             break
//           }
//         }

//         itemsToRemove.push(lineItemId)
//         productsToAdd.push({ id: taxVariantId, quantity, customAttributes: [] })
//       } catch (e) {
//         console.error(e)
//         navigate("/error")
//         rej(e)
//       }
//     }
//   }

//   try {
//     //remove the line item
//     await client.checkout.removeLineItems(cart.id, itemsToRemove)

//     //add the taxless variant with the same quantity
//     await client.checkout.addLineItems(cart.id, productsToAdd)
//   } catch (e) {
//     console.error(e)
//     navigate("/error")
//     rej(e)
//   }

//   res()
// }
