const defaultState = {
  currentProduct: null,
  currentVariant: null,
  preorder: {
    days: "1",
    before: null,
    after: null,
  },
  productPageBlob: "light-purple",
  message: "",
}

const productPage = (state = defaultState, action) => {
  switch (action.type) {
    case "PRODUCT_PAGE_RESET": {
      return defaultState
    }
    case "CURRENT_VARIANT": {
      return {
        ...state,
        currentVariant: action.variant,
      }
    }
    case "CURRENT_PRODUCTS": {
      return {
        ...state,
        currentProduct: action.product,
      }
    }
    case "SET_PREORDER": {
      if (action.preorder === null) {
        return state
      }
      return {
        ...state,
        preorder: action.preorder,
      }
    }
    case "SET_PRODUCT_PAGE_BLOB_COLOR": {
      return {
        ...state,
        productPageBlob: action.color,
      }
    }
    case "PRODUCT_PAGE_CAKE_MESSAGE": {
      return {
        ...state,
        message: action.message,
      }
    }

    default:
      return state
  }
}

export default productPage
