const productsPreorder = (
  state = {
    preorders: {},
  },
  action
) => {
  switch (action.type) {
    case "SET_ADMIN_PRODUCTS_PREORDER": {
      return {
        ...state,
        preorders: action.preorders,
      }
    }
    default:
      return state
  }
}

export default productsPreorder
