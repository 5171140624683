const checkoutPage = (
  state = {
    confirmedDate: null,
    pickupLocation: null,
    preorders: {},
    redirecting: false,
    startDate: null,
    unavailableLocations: { summary: {}, items: {} },
    loading: false,
  },
  action
) => {
  switch (action.type) {
    // case "ADD_PRODUCT_PREORDER": {
    //   return {
    //     ...state,
    //     preorders: {
    //       ...state.preorders,
    //       [action.productId]: action.product,
    //     },
    //   }
    // }
    // case "REMOVE_PRODUCT_PREORDER": {
    //   const preorders = omit(state.preorders, action.id)
    //   const unavailableLocations = omit(state.unavailableLocations, action.id)
    //   return {
    //     ...state,
    //     preorders,
    //     unavailableLocations,
    //   }
    // }
    case "SET_PICKUP_LOCATION": {
      return {
        ...state,
        pickupLocation: action.store,
      }
    }
    case "SET_PICKUP_START_DATE": {
      return {
        ...state,
        startDate: action.date,
      }
    }
    case "SET_PICKUP_CONFIRMED_DATE": {
      return {
        ...state,
        confirmedDate: action.date,
      }
    }

    case "SET_CHECKOUT_REDIRECTING": {
      return {
        ...state,
        redirecting: action.redirecting,
      }
    }

    case "SET_CHECKOUT_LOADING": {
      return {
        ...state,
        loading: action.loading,
      }
    }

    case "SET_CHECKOUT_RESTRICTIONS": {
      return {
        ...state,
        ...action.state,
        loading: false,
      }
    }

    // case "ADD_UNAVAILABLE_LOCATIONS": {
    //   return {
    //     ...state,
    //     unavailableLocations: {
    //       ...state.unavailableLocations,
    //       summary: {
    //         ...state.unavailableLocations.summary,
    //         ...action.summary,
    //       },
    //       [action.productId]: action.product,
    //     },
    //   }
    // }
    default:
      return state
  }
}

export default checkoutPage
