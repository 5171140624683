import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"
import { navigate } from "gatsby"
import { FlexContainer, FlexItem } from "../FlexGrid/FlexGrid"

const Container = styled.div`
  position: relative;
  width: 100%;
  opacity: 0.97;
  background-color: white;
  display: ${({ display }) => display};
`

const Wrapper = styled(FlexContainer)`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 1200px;
  justify-content: flex-start;
`

const TabContainer = styled(FlexItem)`
  position: relative;
  justify-content: center;
  margin-bottom: 1rem;
`

const TabHeader = styled.h4`
  position: relative;
  font-weight: normal;
  margin: 0;
  padding: 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const GET_COLLECTIONS = gql`
  {
    collections(first: 100, query: "-title:All", sortKey: TITLE) {
      edges {
        node {
          id
          handle
          title
        }
      }
    }
  }
`

const SelectBlock = () => {
  //Redux
  const display = useSelector(state => state.showSelectHeader)

  const { error, data } = useQuery(GET_COLLECTIONS)

  if (error) {
    console.error(`Error occured at select block: ${error}`)
    navigate("/error")
    return null
  }

  return (
    <Container display={display ? "" : "none"}>
      <Wrapper>
        {data &&
          data.collections.edges.map(({ node: { handle, title } }) => (
            <TabContainer container xs={3} key={handle}>
              <TabHeader onClick={() => navigate(`/shop/${handle}`)}>
                {title}
              </TabHeader>
            </TabContainer>
          ))}
      </Wrapper>
    </Container>
  )
}

export default React.memo(SelectBlock)
