import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTransition } from "react-spring"

import "./layout.css"

import { connect } from "react-redux"
import { setDecodedToken, setAdmin } from "../actions/admin/setAdmin"
import AdminMenuIcon from "./Header/AdminMenuIcon"
import AdminNavBar from "./Admin/AdminNavBar"
import SEO from "./seo"
import { removePastPickups } from "../APIs/removePastPickups"

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

const MenuContainer = styled.div`
  position: sticky;
  top: 20px;
  left: 20px;
  z-index: 20;
  display: inline-block;
`

const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const AdminLayout = ({
  children,
  connected,
  decodedToken,
  dispatch,
  restrictions,
}) => {
  const navWidth = 300

  const [showMenu, setShowMenu] = useState(false)

  const transitions = useTransition(showMenu, null, {
    from: { transform: "translate3d(-100%, 0, 0)" },
    enter: { transform: "translate3d(0, 0, 0)" },
    leave: { transform: "translate3d(-100%, 0, 0)" },
  })

  useEffect(() => {
    //Check if a user is logged in on initialization.
    dispatch(setAdmin())
  }, [dispatch])

  useEffect(() => {
    //If the user is connected by not authenticated, check authentication (requires backend).
    if (connected) {
      dispatch(setDecodedToken())
    }
  }, [dispatch, connected])

  useEffect(() => {
    if (restrictions.restrictionByDate && connected && decodedToken !== null) {
      removePastPickups(restrictions, dispatch)
    }
  }, [restrictions, connected, decodedToken])

  return (
    <Root navWidth={connected && decodedToken !== null ? navWidth : 0}>
      <SEO title="Admin Page" />
      {connected && decodedToken !== null ? (
        <>
          <MenuContainer>
            <MenuWrapper onClick={() => setShowMenu(!showMenu)}>
              <AdminMenuIcon open={showMenu} />
            </MenuWrapper>
          </MenuContainer>

          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <AdminNavBar key={key} style={props} setOpen={setShowMenu} />
              )
          )}
        </>
      ) : null}
      {children}
    </Root>
  )
}

const mapStateToProps = ({
  adminPage: {
    general: { connected, decodedToken },
  },
  stores: { restrictions },
}) => ({ connected, decodedToken, restrictions })

export default connect(mapStateToProps)(AdminLayout)
