// import firebase from "gatsby-plugin-firebase"
import apolloClient from "../../lib/shopifyApollo"
import gql from "graphql-tag"
import pick from "lodash/pick"

import "firebase/database"

const GET_PRODUCT_PREORDER = gql`
  query MyQuery($handle: String!) {
    product(handle: $handle) {
      id
      metafield(key: "preorder-restriction", namespace: "global") {
        value
      }
    }
  }
`
const GET_PRODUCT_LOCATION = gql`
  query MyQuery($handle: String!) {
    product(handle: $handle) {
      id
      metafield(key: "unavailable-at", namespace: "global") {
        value
      }
    }
  }
`

export const setCheckoutRestrictions = ({ data }) => async (
  dispatch,
  getState
) => {
  const {
    checkoutPage: { preorders, unavailableLocations },
    stores: { locations },
  } = getState()
  const toDispatch = {
    unavailableLocations,
    preorders,
  }

  //Handle all the restriction to be added.
  for (const handle of data.add) {
    if (!preorders[handle]) {
      //Add preorder restrictions
      try {
        //Wait for apollo query of the product
        const result = await apolloClient.query({
          query: GET_PRODUCT_PREORDER,
          variables: { handle },
        })

        if (result.data) {
          const { id: productId, metafield } = result.data.product
          if (metafield) {
            toDispatch.preorders = {
              ...toDispatch.preorders,
              [productId]: JSON.parse(metafield.value),
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    if (!unavailableLocations[handle]) {
      //Add location restrictions
      try {
        //Wait for apollo query of the product
        const result = await apolloClient.query({
          query: GET_PRODUCT_LOCATION,
          variables: { handle },
        })

        if (result.data) {
          const { id: productId, metafield } = result.data.product
          if (metafield) {
            const value = JSON.parse(metafield.value)
            const summary = {}
            const product = value.map(cv => {
              summary[cv] = true
              return cv
            })

            toDispatch.unavailableLocations = {
              ...toDispatch.unavailableLocations,
              summary: {
                ...toDispatch.unavailableLocations.summary,
                ...summary,
              },
              items: {
                ...toDispatch.unavailableLocations.items,
                [productId]: product,
              },
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  //Handle all the restrictions to be removed.
  if (data.remove.length > 0) {
    toDispatch.preorders = pick(toDispatch.preorders, data.productIds)

    const items = pick(toDispatch.unavailableLocations.items, data.productIds)
    toDispatch.unavailableLocations = {
      items,
      summary: Object.values(items).reduce((acc, cv) => {
        if (cv.length > 0) {
          Object.assign(acc, { [cv]: true })
        }
        return acc
      }, {}),
    }
  }

  dispatch({
    type: "SET_CHECKOUT_RESTRICTIONS",
    state: toDispatch,
  })
}

// export const addCheckoutProducts = handle => async (dispatch, getState) => {
//   try {
//     //Wait for apollo query of the product
//     const result = await apolloClient.query({
//       query: GET_PRODUCT_PREORDER,
//       variables: { handle },
//     })

//     if (result.data) {
//       const { id: productId, metafield } = result.data.product
//       metafield &&
//         dispatch({
//           type: "ADD_PRODUCT_PREORDER",
//           product: JSON.parse(metafield.value),
//           productId,
//         })
//     }
//   } catch (e) {
//     console.error(e)
//   }

//   try {
//     //Wait for apollo query of the product
//     const result = await apolloClient.query({
//       query: GET_PRODUCT_LOCATION,
//       variables: { handle },
//     })

//     const {
//       stores: { locations },
//     } = getState()

//     if (result.data) {
//       const { id: productId, metafield } = result.data.product
//       if (metafield) {
//         const value = JSON.parse(metafield.value)
//         const summary = {}
//         const product = value.map(cv => {
//           summary[cv] = true
//           return locations[cv].name
//         })

//         metafield &&
//           dispatch({
//             type: "ADD_UNAVAILABLE_LOCATIONS",
//             product,
//             productId,
//             summary,
//           })
//       }
//     }
//   } catch (e) {
//     console.error(e)
//   }
// }

// export const removeCheckoutProducts = id => ({
//   type: "REMOVE_PRODUCT_PREORDER",
//   id,
// })

export const setCheckoutRedirecting = redirecting => ({
  type: "SET_CHECKOUT_REDIRECTING",
  redirecting,
})

export const setPickupLocation = store => ({
  type: "SET_PICKUP_LOCATION",
  store,
})

export const setPickupStartDate = date => ({
  type: "SET_PICKUP_START_DATE",
  date,
})

export const setPickupConfirmedDate = date => ({
  type: "SET_PICKUP_CONFIRMED_DATE",
  date,
})

export const setCheckoutLoading = state => ({
  type: "SET_CHECKOUT_LOADING",
  loading: state,
})
