const showSideModal = (state = { show: false, version: "cart" }, action) => {
  switch (action.type) {
    case "TOGGLE_SIDE_MODAL": {
      return {
        show: action.show,
        version: action.version ? action.version : state.version,
      }
    }
    default:
      return state
    // return true
  }
}

export default showSideModal
