const showSelectHeader = (state = false, action) => {
  switch (action.type) {
    case "TOGGLE_SELECT_HEADER": {
      return action.open
    }
    default:
      return state
  }
}

export default showSelectHeader
