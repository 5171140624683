const isBrowser = typeof window !== `undefined`

var supportsPassive = false
try {
  var opts = Object.defineProperty({}, "passive", {
    get: function () {
      supportsPassive = true
      return supportsPassive
    },
  })
  window.addEventListener("testPassive", null, opts)
  window.removeEventListener("testPassive", null, opts)
} catch (e) {}

function getScrollPosition() {
  return isBrowser
    ? { x: window.pageXOffset, y: window.pageYOffset }
    : { x: 0, y: 0 }
}

let lockStateCache

function isSameAsCache(lock) {
  if (!lockStateCache || lockStateCache !== JSON.stringify(lock)) {
    lockStateCache = JSON.stringify(lock)
    return false
  } else {
    return true
  }
}

//Lock Body function for modals.
export default function lockBody(lock) {
  if (!isSameAsCache(lock)) {
    const scrollPosition = getScrollPosition()
    if (lock && scrollPosition) {
      document.body.style.top = `-${scrollPosition.y}px`
      document.body.style.position = "fixed"
      // document.body.style.overflowY = "hidden"
    } else {
      //Generates the number to scroll to based on the "top" style of the body. Neccesary due to race conditions with the scroll state.
      const scrollTo = Number(document.body.style.top.replace(/\-*(px)*/g, ""))
      //Changes the body back to the default settings
      document.body.style.top = ""
      document.body.style.position = ""
      // document.body.style.overflowY = ""
      window.scrollTo(0, scrollTo)
    }
  }
}
