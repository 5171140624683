import { useState, useEffect } from "react"

const isBrowser = typeof window !== `undefined`

var supportsPassive = false
try {
  var opts = Object.defineProperty({}, "passive", {
    get: function () {
      supportsPassive = true
      return supportsPassive
    },
  })
  window.addEventListener("testPassive", null, opts)
  window.removeEventListener("testPassive", null, opts)
} catch (e) {}

function getScrollPosition() {
  return isBrowser
    ? { x: window.pageXOffset, y: window.pageYOffset }
    : { x: 0, y: 0 }
}

export default function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(getScrollPosition())

  useEffect(() => {
    let requestRunning = null

    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition())
          requestRunning = null
        })
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", handleScroll, {
        passive: supportsPassive,
      })
      return () =>
        window.removeEventListener("scroll", handleScroll, {
          passive: supportsPassive,
        })
    }
  }, [])

  return { scrollPosition }
}
