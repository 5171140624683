const reports = (
  state = {
    csv: [],
    locations: {},
    locationFilter: "",
    productTypeFilter: "",
    productTypes: {},
    orders: [],
    sortedOrders: [],
    summary: {},
  },
  action
) => {
  switch (action.type) {
    case "ADD_ADMIN_REPORTS_LOCATION": {
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.location]: true,
        },
      }
    }
    case "SET_ADMIN_REPORTS_ORDERS": {
      return {
        ...state,
        orders: action.orders,
      }
    }
    case "SET_ADMIN_REPORTS_SORTED_ORDERS": {
      return {
        ...state,
        sortedOrders: action.sortedOrders,
      }
    }
    case "SET_ADMIN_REPORTS_SUMMARY": {
      return {
        ...state,
        summary: action.summary,
      }
    }
    case "SET_ADMIN_REPORTS_CSV": {
      return { ...state, csv: action.csv }
    }
    case "SET_ADMIN_REPORTS_LOCATION_FILTER": {
      return {
        ...state,
        locationFilter: action.location,
      }
    }
    case "SET_ADMIN_REPORTS_PRODUCT_TYPE_FILTER": {
      return {
        ...state,
        productTypeFilter: action.productType,
      }
    }
    case "SET_ADMIN_ADD_PRODUCT_TYPE": {
      return {
        ...state,
        productTypes: action.productTypes,
      }
    }
    default:
      return state
  }
}

export default reports
