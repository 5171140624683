import React from "react"
import "./MenuIcon.css"

const MenuIcon = ({ open }) => {
  return (
    <div id="nav-icon1" className={open ? "open" : ""}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default MenuIcon
