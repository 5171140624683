import omit from "lodash/omit"

const getUnavailableLocations = ({ state, items }) => {
  const locationsToRemove = []

  for (let key in items) {
    const lineItem = items[key]
    const {
      product: { metafields },
    } = lineItem

    const unavailableAt = metafields.filter(
      (field) => field?.key === "unavailable-at"
    )

    if (unavailableAt.length > 0) {
      const { value } = unavailableAt[0]
      const parse = JSON.parse(value)

      for (let location of parse) {
        if (!locationsToRemove.includes(location)) {
          locationsToRemove.push(location)
        }
      }
    }
  }

  return locationsToRemove
}

const initialState = {
  appliedDiscount: null,
  confirmedDate: null,
  customerId: "",
  itemsToAdd: {},
  location: "",
  note: "",
  selectedProducts: {},
  startDate: null,
  storeLocations: {},
  unavailableLocations: {},
}

const createOrder = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ORDER_DISCOUNT": {
      return {
        ...state,
        appliedDiscount: action.discount,
      }
    }
    case "CREATE_ORDER_ADD_TO_SELECTED_PRODUCTS": {
      return {
        ...state,
        selectedProducts: {
          ...state.selectedProducts,
          [action.id]: { ...action.variant },
        },
      }
    }

    case "CREATE_ORDER_DELETE_TO_SELECTED_PRODUCTS": {
      const selectedProducts = omit(
        Object.assign(state.selectedProducts),
        action.id
      )

      return {
        ...state,
        selectedProducts,
      }
    }

    case "CREATE_ORDER_CLEAR_SELECTED_PRODUCTS": {
      return {
        ...state,
        selectedProducts: {},
      }
    }

    case "CREATE_ORDER_SELECTED_PRODUCTS_TO_ITEMS_TO_ADD": {
      const { selectedProducts, itemsToAdd, unavailableLocations } = state

      const items = Object.keys(selectedProducts).reduce((acc, cv) => {
        const lineItem = selectedProducts[cv]

        acc[cv] = Object.assign(lineItem)
        acc[cv].quantity = 1
        acc[cv].customAttributes = []
        return acc
      }, {})

      const locationsToRemove = getUnavailableLocations({
        state,
        items: selectedProducts,
      })

      const newUnavailableLocations = Object.assign(unavailableLocations)

      for (let location of locationsToRemove) {
        newUnavailableLocations[location] = true
      }

      return {
        ...state,
        selectedProducts: {},
        itemsToAdd: {
          ...items,
          ...itemsToAdd,
        },
        unavailableLocations: newUnavailableLocations,
      }
    }

    case "CREATE_ORDER_UPDATE_ITEM": {
      return {
        ...state,
        itemsToAdd: {
          ...state.itemsToAdd,
          [action.id]: {
            ...state.itemsToAdd[action.id],
            [action.key]: action.value,
          },
        },
      }
    }

    case "CREATE_ORDER_DELETE_ITEM": {
      const itemsToAdd = omit(Object.assign(state.itemsToAdd), action.id)
      const newUnavailableLocations = getUnavailableLocations({
        state,
        items: itemsToAdd,
      }).reduce((acc, cv) => {
        acc[cv] = true
        return acc
      }, {})

      return {
        ...state,
        itemsToAdd,
        unavailableLocations: newUnavailableLocations,
      }
    }

    case "CREATE_ORDER_LOCATION": {
      return {
        ...state,
        location: action.location,
      }
    }

    case "CREATE_ORDER_STORE_LOCATIONS": {
      return {
        ...state,
        storeLocations: action.locations,
      }
    }

    case "CREATE_ORDER_HOURS": {
      return {
        ...state,
        storeLocations: {
          ...state.storeLocations,
          [action.id]: {
            ...state.storeLocations[action.id],
            hours: action.hours,
          },
        },
      }
    }

    case "CREATE_ORDER_PICKUP_START_DATE": {
      return {
        ...state,
        startDate: action.date,
      }
    }

    case "CREATE_ORDER_PICKUP_CONFIRMED_DATE": {
      return {
        ...state,
        confirmedDate: action.date,
      }
    }

    case "CREATE_ORDER_NOTE": {
      return {
        ...state,
        note: action.note,
      }
    }

    case "CREATE_ORDER_CUSTOMER_ID": {
      return {
        ...state,
        customerId: action.id,
      }
    }

    case "CREATE_ORDER_RESET": {
      return {
        ...initialState,
        storeLocations: state.storeLocations,
      }
    }

    default:
      return state
  }
}

export default createOrder
