import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setCart } from "../../actions/setCart"
import { setUser } from "../../actions/setAccount"
import firebase from "gatsby-plugin-firebase"
import "firebase/database"

//date-fns
import getYear from "date-fns/getYear"
import getMonth from "date-fns/getMonth"
import getDate from "date-fns/getDate"
import getHours from "date-fns/getHours"
import getMinutes from "date-fns/getMinutes"

import client from "../../lib/shopifyClient"

const CartClient = ({ children, dispatch }) => {
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    initializeCheckout()
    getUser()
  }, [])

  const getUser = () => {
    try {
      if (isBrowser) {
        const user = JSON.parse(localStorage.getItem("user_access_token"))
        dispatch(setUser(user))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getNewId = async () => {
    try {
      const newCheckout = await client.checkout.create()
      if (isBrowser) {
        localStorage.setItem("checkout_id", newCheckout.id)
      }
      return newCheckout
    } catch (e) {
      console.error(e)
    }
  }

  const initializeCheckout = async () => {
    try {
      //Check if id exists
      const currentCheckoutId = isBrowser
        ? localStorage.getItem("checkout_id")
        : null

      let newCheckout = null


      if (currentCheckoutId) {
        //If id exists, fetch checkout from Shopify
        newCheckout = await client.checkout.fetch(currentCheckoutId)

        if (newCheckout === null) {
          //If id does not exist, create new checkout
          newCheckout = await getNewId()
        }
        if (newCheckout?.completedAt) {
          const reducedCustomAttributes = newCheckout.customAttributes.reduce(
            (acc, cv) => {
              acc[cv.key] = cv.value
              return acc
            },
            {}
          )

          const location = reducedCustomAttributes["pickup-location"]
          const date = reducedCustomAttributes["raw-date"]
          const newDate = new Date(date)
          const year = getYear(newDate)
          const month = getMonth(newDate) + 1 //getMonth starts at 0
          const day = getDate(newDate)
          const hour = getHours(newDate)
          const minute = getMinutes(newDate)

          //Check if checkout has already been saved to firebase.
          const pickupExists = await firebase
            .database()
            .ref(
              `pickups/${location}/${year}/${month}/${day}/${hour}/${minute}`
            )
            .once("value")
            .then(snapshot => {
              if (snapshot.val() === null) {
                return false
              } else {
                return Object.keys(snapshot.val()).includes(newCheckout.id)
              }
            })

          if (pickupExists) {
            newCheckout = await getNewId()
          } else {
            const savePickup = await firebase
              .database()
              .ref(
                `pickups/${location}/${year}/${month}/${day}/${hour}/${minute}`
              )
              .update({
                [newCheckout.id]: date,
              })
              .then(res => {
                return { completed: true }
              })
              .catch(err => {
                console.error(err)
                return { completed: false }
              })

            if (savePickup.completed) {
              newCheckout = await getNewId()
            }
          }
          // if (!newCheckout || newCheckout.completedAt) {
          //   newCheckout = await getNewId()
          // }
          // }
        }
      } else {
        //If id does not exist, create new checkout
        newCheckout = await getNewId()
      }

      //Set checkout to state
      dispatch(setCart(newCheckout))
    } catch (e) {
      console.error(e)
    }
  }

  return <>{children}</>
}

export default connect()(CartClient)
