const isSideBarOpen = (state = false, action) => {
  switch (action.type) {
    case "TOGGLE_SIDE_BAR": {
      return action.open
    }
    default:
      return state
  }
}

export default isSideBarOpen
