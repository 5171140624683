import ApolloClient from "apollo-boost"

// //Apollo
const client = new ApolloClient({
  uri: `https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
  headers: {
    Accept: "application/json",
    "X-Shopify-Storefront-Access-Token":
      process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  },
})

export default client
