const stores = (
  state = {
    locations: {},
    instagram: [],
    restrictions: {
      defaultPerSlot: {},
      restrictionByDate: {},
      endOfDay: {},
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_STORES_LOCATIONS": {
      return {
        ...state,
        locations: action.locations,
      }
    }
    case "INITIAL_SET_STORES": {
      return {
        ...state,
        locations: action.locations,
        instagram: action.instagram || state.instagram,
        restrictions: action.restrictions,
      }
    }
    case "INITIALIZE_INSTAGRAM": {
      return {
        ...state,
        instagram: action.instagram,
      }
    }
    case "SET_STORE_RESTRICTIONS": {
      return {
        ...state,
        restrictions: {
          ...state.restrictions,
          ...action.restrictions,
        },
      }
    }
    case "ADD_RESTRICTIONS_TO_STORE": {
      return {
        ...state,
        restrictions: action.restrictions,
      }
    }
    case "ADD_STORE_HOURS_TO_LOCATION": {
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.id]: {
            ...state.locations[action.id],
            hours: action.storeHours,
          },
        },
        restrictions: action.restrictions,
      }
    }
    case "ADD_PICKUP_HOURS_TO_LOCATION": {
      return {
        ...state,
        locations: {
          ...state.locations,
          [action.id]: {
            ...state.locations[action.id],
            pickupHours: action.pickupHours,
          },
        },
        restrictions: action.restrictions,
      }
    }
    default:
      return state
  }
}

export default stores
