import omit from "lodash/omit"

const pickupSchedule = (
  state = { inView: null, tempAddition: null },
  action
) => {
  switch (action.type) {
    case "SET_ADMIN_PICKUP_SCHEDULE_IN_VIEW": {
      return {
        ...state,
        inView: action.inView,
        tempAddition: null,
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_NUMBER_OF_PICKUPS": {
      const inView = action.inView.split("|")
      const year = inView[0]
      const month = inView[1]
      const day = inView[2]

      return {
        ...state,
        [year]: {
          ...state[year],
          [month]: {
            ...state[year][month],
            [day]: {
              ...state[year][month][day],
              numberOfPickups: action.value,
            },
          },
        },
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_START_TIME": {
      const inView = action.inView.split("|")
      const year = inView[0]
      const month = inView[1]
      const day = inView[2]

      return {
        ...state,
        [year]: {
          ...state[year],
          [month]: {
            ...state[year][month],
            [day]: {
              ...state[year][month][day],
              startTime: action.value,
            },
          },
        },
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_END_TIME": {
      const inView = action.inView.split("|")
      const year = inView[0]
      const month = inView[1]
      const day = inView[2]

      return {
        ...state,
        [year]: {
          ...state[year],
          [month]: {
            ...state[year][month],
            [day]: {
              ...state[year][month][day],
              endTime: action.value,
            },
          },
        },
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_VALUE": {
      const inView = state.inView.split("|")
      const year = inView[0]
      const month = inView[1]
      const day = inView[2]

      return {
        ...state,
        [year]: {
          ...state[year],
          [month]: {
            ...state[year][month],
            [day]: action.value,
          },
        },
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_TEMP_ADDITION": {
      return {
        ...state,
        inView: null,
        tempAddition: action.value,
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_CANCEL": {
      return {
        ...state,
        inView: null,
        tempAddition: null,
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_SAVE_TEMP": {
      const inView = state.tempAddition.split("|")
      const year = inView[0]
      const month = inView[1]
      const day = inView[2]

      return {
        ...state,
        inView: state.tempAddition,
        tempAddition: null,
        [year]: {
          ...state[year],
          [month]: {
            ...state[year]?.[month],
            [day]: action.value,
          },
        },
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE": {
      return {
        ...state,
        ...action.schedule,
      }
    }

    case "SET_ADMIN_PICKUP_SCHEDULE_REMOVE": {
      const { year, month, day } = action

      const yearSch = Object.assign(state[year])
      const monthSch = Object.assign(yearSch[month])

      return {
        ...state,
        inView: null,
        tempAddition: null,
        [year]: {
          ...yearSch,
          [month]: omit(monthSch, day),
        },
      }
    }

    default:
      return state
  }
}

export default pickupSchedule
