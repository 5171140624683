import client from "../lib/shopifyClient"

import handleTaxVariants from "../APIs/handleTaxVariants"
import { setCheckoutRedirecting } from "./checkout/setCheckPage"
import { navigate } from "gatsby"

const sortAttributes = attributes => {
  /*
  {a: something, b: something else}
  
  to

  [{key: "a", value: "something"}, {key: "b", value: "something else"}]
  */
  const output = Object.entries(attributes).reduce((accum, [k, v]) => {
    accum.push({ key: k, value: v })
    return accum
  }, [])

  return output
}

export const addProductToCart = ({
  variantId,
  quantity,
  customAttributes,
}) => async (dispatch, getState) => {
  try {
    const newAttributes = customAttributes && sortAttributes(customAttributes)
    const lineItems = [
      {
        variantId,
        quantity: quantity ? quantity : 1,
        customAttributes: customAttributes && newAttributes,
      },
    ]

    const newCheckout = await client.checkout.addLineItems(
      getState().cart.id,
      lineItems
    )

    dispatch(setCart(newCheckout))
  } catch (e) {
    console.error(e)
  }
}

export const removeProductFromCart = ({ id: lineItemId, res }) => async (
  dispatch,
  getState
) => {
  try {
    const newCheckout = await client.checkout.removeLineItems(
      getState().cart.id,
      [lineItemId]
    )
    dispatch(setCart(newCheckout))
    if (res) {
      return res()
    }
  } catch (e) {
    console.error(e)
  }
}

export const updateProduct = ({ res, rej, ...update }) => async (
  dispatch,
  getState
) => {
  try {
    const newCheckout = await client.checkout.updateLineItems(
      getState().cart.id,
      update
    )

    dispatch(setCart(newCheckout))

    if (res) {
      return res()
    }
  } catch (e) {
    console.error(e)
  }
}

export const setCart = cart => ({
  type: "SET_CART",
  cart,
})

export const updatePickupDateForCheckout = attribute => async (
  dispatch,
  getState
) => {
  dispatch(setCheckoutRedirecting(true))

  try {
    const { cart } = getState()
    const formattedAttr = sortAttributes(attribute)

    const result = await new Promise((res, rej) =>
      handleTaxVariants({ cart, res, rej })
    )

    //Update the pickup time & location to cart
    const newCheckout = await client.checkout.updateAttributes(cart.id, {
      customAttributes: formattedAttr,
    })
    dispatch(setCart(newCheckout))

    // return window.location.replace(cart.webUrl)
    return (window.location = cart.webUrl)
  } catch (e) {
    console.error(`Error occured at set cart: ${e}`)
    navigate("/error")
  }
}
