import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { toggleSideBar } from "../../actions/toggleSideBar"
import breakPoints from "../../APIs/devices"
import MenuIcon from "./MenuIcon"

const Container = styled.div`
  position: relative;
  height: 100%;
  flex-grow: 1;
  flex-basis: 33%;
`
const WrapperButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 35px;
  border: none;
  margin-left: -5px;
  margin-top: -2px;
  background: transparent;
  z-index: var(--mobile-menu-button-zIndex);
  @media only screen and (min-width: ${breakPoints.lg}) {
    display: none;
  }
`
const MobileMenu = ({ isSideBarOpen, dispatch }) => {
  return (
    <Container>
      <WrapperButton onClick={() => dispatch(toggleSideBar(!isSideBarOpen))}>
        <MenuIcon />
      </WrapperButton>
    </Container>
  )
}

MobileMenu.propTypes = {
  dispatch: PropTypes.func,
}

const mapStateToProps = ({ isSideBarOpen }) => {
  return {
    isSideBarOpen,
  }
}

export default connect(mapStateToProps)(MobileMenu)
