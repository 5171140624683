import { combineReducers } from "redux"
import isSideBarOpen from "./isSideBarOpen"
import productPage from "./productPage"
import showSideModal from "./showSideModal"
import cart from "./cart"
import siteLocation from "./siteLocation"
import mobileSite from "./mobileSite"
import user from "./user"
import showSelectHeader from "./showSelectHeader"
import macaronPage from "./macaronPage"
import adminPage from "./adminPage/index"
import checkoutPage from "./checkout/checkoutPage"
import stores from "./stores/stores"
import addToCartPopup from "./addToCartPopup"
import locationsPage from "./locationsPage/locationsPage"

export default combineReducers({
  addToCartPopup,
  adminPage,
  cart,
  checkoutPage,
  isSideBarOpen,
  locationsPage,
  macaronPage,
  mobileSite,
  productPage,
  showSelectHeader,
  showSideModal,
  siteLocation,
  stores,
  user,
})
