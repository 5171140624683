const addToCartPopup = (state = { on: 0, content: "" }, action) => {
  switch (action.type) {
    case "ADD_TO_CART_POPUP_ON": {
      return {
        ...state,
        on: 1,
        content: "🎉Added!🎉",
      }
    }
    case "ADD_TO_CART_POPUP_OFF": {
      return {
        ...state,
        on: 0,
      }
    }
    default:
      return state
  }
}

export default addToCartPopup
