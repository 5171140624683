const manageStores = (
  state = {
    storeHours: {},
    pickupHours: null,
    stores: [],
  },
  action
) => {
  switch (action.type) {
    case "SET_ADMIN_STORES": {
      return {
        ...state,
        stores: action.stores,
      }
    }

    case "SET_ADMIN_STORE_HOURS": {
      return {
        ...state,
        storeHours: action.hours,
      }
    }

    case "SET_ADMIN_PICKUP_HOURS": {
      return {
        ...state,
        pickupHours: action.hours,
      }
    }

    default:
      return state
  }
}

export default manageStores
