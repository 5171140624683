import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import firebase from "gatsby-plugin-firebase"
import "firebase/auth"
import { useSelector, useDispatch } from "react-redux"
import { animated } from "react-spring"

import {
  increaseAdminLoading,
  decreaseAdminLoading,
  adminLogout,
} from "../../actions/admin/setAdmin"

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const NavContainer = styled(animated.nav)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  padding: 8px;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  background-color: white;
`

const LoginStatusContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  font-size: 13px;
  padding: 8px;
  margin: -8px;
`

const BackToContainer = styled.div`
  margin: 50px 0 25px;
`

const LinksContainer = styled.div`
  margin: 25px 0 25px;
`

const LinkList = styled.ul`
  list-style: none;
  margin-left: 1rem;
`

const LinkListItem = styled.li`
  &:hover {
    cursor: pointer;
  }
`

const SignOutButton = styled.button`
  width: 100%;
  font-size: 16px;
  margin: 20px 0 20px auto;
  border: none;
  border-radius: 5px;
  height: 50px;
  align-self: flex-end;
  &:hover {
    cursor: pointer;
  }
`

const AdminNavBar = ({ style, setOpen }) => {
  //Redux
  const dispatch = useDispatch()
  const { decodedToken } = useSelector(state => state.adminPage.general)

  const logOut = () => {
    //Set Loading.
    dispatch(increaseAdminLoading())

    //Sign out of firebase.
    firebase
      .auth()
      .signOut()
      .then(res => {
        //Set Loading off.
        dispatch(decreaseAdminLoading())
        dispatch(adminLogout())
        //Navigate back to main page.
        navigate("/admin")
      })
      .catch(err => window.alert(err.message))
  }

  return (
    <Root>
      <Background onClick={() => setOpen(false)} />
      <NavContainer style={style}>
        <BackToContainer>
          <hr />
          <Link to="/">Back to Main Site</Link>
        </BackToContainer>
        <hr />
        <LinksContainer>
          <LinkList>
            <li>
              <h3>Store</h3>
            </li>
            <LinkList>
              <LinkListItem
                onClick={() => {
                  setOpen(false)
                  navigate("/admin/store/manage")
                }}
              >
                Manage Stores
              </LinkListItem>
              <LinkListItem
                onClick={() => {
                  setOpen(false)
                  navigate("/admin/store/end_of_day")
                }}
              >
                End Of Day
              </LinkListItem>
            </LinkList>

            <li>
              <h3>Products</h3>
            </li>
            <LinkList>
              <LinkListItem
                onClick={() => {
                  setOpen(false)
                  navigate("/admin/products/preorder")
                }}
              >
                Restrictions
              </LinkListItem>
            </LinkList>

            <li>
              <h3>Orders</h3>
            </li>
            <LinkList>
              <LinkListItem
                onClick={() => {
                  setOpen(false)
                  navigate("/admin/orders/report")
                }}
              >
                Orders Report
              </LinkListItem>
              <LinkListItem
                onClick={() => {
                  setOpen(false)
                  navigate("/admin/orders/create")
                }}
              >
                Create New Order
              </LinkListItem>
            </LinkList>
          </LinkList>
        </LinksContainer>

        <LoginStatusContainer>
          <hr />

          <div>
            <u>Logged in as:</u>
          </div>
          <div>{decodedToken.email}</div>

          <SignOutButton onClick={logOut}>Log Out</SignOutButton>
        </LoginStatusContainer>
      </NavContainer>
    </Root>
  )
}

export default AdminNavBar
